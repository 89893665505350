/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-form-field {
    width: 100%;
}

.mat-error {
    font-size: 1.2em;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 10px;
}

.material-select div.mat-form-field-wrapper div.mat-form-field-flex div.mat-form-field-infix,
.material-select div.mat-form-field-wrapper div.mat-form-field-flex,
.material-select div.mat-form-field-wrapper {
    padding: 0px;
    border-top: 0px;
}

.material-select div.mat-form-field-wrapper div.mat-form-field-underline,
.material-select div.mat-form-field-wrapper div.mat-form-field-underline span.mat-form-field-ripple {
    height: 0px;
}

.mat-menu-panel {
    max-width: 350px !important;
}

.button-overlay>.button-container {
    position: relative;
}

.button-overlay {
    z-index: 0;
}

.button-overlay>.button-container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
}

.button-overlay>.button-container:after {
    background-color: #dd1606;
    opacity: 1;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    transform: rotate( 179deg);
    z-index: -1;
}

.button-overlay>.button-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
}

.button-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
}

button.alternativa-css {
    background-color: transparent;
    border: none;
    padding: 5px 25px;
    font-size: 1.5em;
    color: white;
    margin: 0px;
    font-weight: bold;
    min-width: 200px
}

button.alternativa-css:hover {
    cursor: pointer;
}

button.alternativa-css:disabled {
    cursor: not-allowed;
}

.form-control {
    border-radius: 0px;
    height: 45px;
    padding: 10px;
}

.form-control:focus {
    border-color: #ccc;
    box-shadow: none;
}

.custom-tooltip {
    font-size: 1.2em;
    line-height: 1em;
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
    color: black;
}

button.mat-menu-item {
    display: flex;
    align-items: center;
}

.angular-editor-toolbar {
    font-size: 1.5em !important;
}

.mat-radio-label-content {
    font-weight: 500;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #ed7422;
}

button.mat-raised-button,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #ed7422;
    color: white;
}

.mat-tab-body-content {
    padding: 20px 0px;
}

.mat-raised-button.mat-success {
    background-color: #51df57;
    color: white;
}

.button-row .mat-button-base {
    margin: 8px 8px 8px 0;
    padding: 5px 40px;
}

#insertVideo-,
#insertHorizontalRule-,
#clearFormatting-,
#indent-,
#outdent-,
#subscript-,
#superscript- {
    display: none;
}

.country-search {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 9;
}

.country-selector {
    opacity: 1;
}

@media (max-width: 768px) {
    .angular-editor-toolbar {
        font-size: 0.7em !important;
    }
}

@media (min-width: 768px) {
    .container-iscritti {
        width: 100%;
        padding: 20px 50px;
    }
    .container-iscritti-front {
        width: 100%;
        padding: 20px 50;
    }
    .angular-editor-toolbar {
        font-size: 1em !important;
    }
}

@media (min-width: 992px) {
    .container-iscritti {
        width: 100%;
        padding: 20px 70px;
    }
    .container-iscritti-front {
        width: 100%;
        padding: 20px 100px;
    }
}

@media (min-width: 1200px) {
    .container-iscritti {
        width: 100%;
        padding: 20px 150px;
    }
    .container-iscritti-front {
        width: 100%;
        padding: 20px 250px;
    }
}